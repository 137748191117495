<template>
    <div v-if="component_done_loading" id="account-view" class="col-xs-12 right-container">
        <!-- <div class="page-title">
      <div class="col-xs-12 col-md-6" style="margin-bottom:30px;">
        <router-link :to="{ name: 'accounts.index' }">Accounts</router-link>
        / View Account
        <h3 style="margin-bottom:0;"></h3>
      </div>
      <div class="col-xs-12 col-md-6 listingHeaderBtns">
        <router-link
          :to="{ name: 'accounts.index' }"
          style="color:#222;"
          class="btn btn-action btn-default"
          ><i class="fa fa-chevron-left"></i> Back to Account List</router-link
        >
      </div>
    </div>
    <div class="clearfix"></div> -->

        <div class="links">
            <ul>
                <li :class="{ active: tab == 'account' }">
                    <a class="nav-link" data-toggle="tab" href="#account" aria-label="Overview">OVERVIEW</a>
                </li>
                <li :class="{ active: tab == 'superadmin' }">
                    <a class="nav-link" data-toggle="tab" href="#superadmin" aria-label="superadmins">SUPER ADMINS</a>
                </li>
                <li :class="{ active: tab == 'teacher' }">
                    <a class="nav-link" data-toggle="tab" href="#teacher" aria-label="Teachers">TEACHERS</a>
                </li>
                <li :class="{ active: tab == 'student' }">
                    <a class="nav-link" data-toggle="tab" href="#student" aria-label="Students">STUDENTS</a>
                </li>
                <li v-if="account.type == 'Paid' && account.paymentMethod == 'Student-Paid'" :class="{ active: tab == 'code' }">
                    <a class="nav-link" data-toggle="tab" href="#codes" aria-label="Access Codes">ACCESS CODES</a>
                </li>

                <!-- <li :class="{ active: tab == 'log' }">
          <a class="nav-link" data-toggle="tab" href="#log">ACTIVITY LOG</a>
        </li> -->

                <li v-if="account.type == 'Paid' && account.paymentMethod == 'Student-Paid'" :class="{ active: tab == 'payment-log' }">
                    <a class="nav-link" data-toggle="tab" href="#payment-log" aria-label="Payment Log">PAYMENT LOG</a>
                </li>
            </ul>
        </div>

        <div class="row tab-content">
            <div id="account" class="tab-pane fade in" :class="{ active: tab == 'account' }">
                <kr-panel :with-footer="false">
                    <template #title>
                        <div class="col-xs-6">OVERVIEW</div>
                        <div class="col-xs-6 buttonGroup flexRight">
                            <div class="hidden-xs hidden-sm">
                                <router-link
                                    v-slot="{ navigate }"
                                    :to="{
                                        name: 'accounts.edit',
                                        params: $route.params.id,
                                    }"
                                    custom
                                >
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                                    </button>
                                </router-link>

                                <button
                                    v-if="!account.isSuspended"
                                    id="top-reset-pwd-btn"
                                    class="btn btn-default mainBtn"
                                    aria-label="Reset Password"
                                    @click.prevent="openResetPasswordModal(account, 'account')"
                                >
                                    <i class="fa fa-key marginRight8" aria-hidden="true" />Reset Password
                                </button>

                                <router-link v-slot="{ navigate }" :to="{ name: 'accounts.index' }" custom>
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back
                                    </button>
                                </router-link>
                            </div>
                            <div class="hidden-md hidden-lg">
                                <router-link
                                    v-slot="{ navigate }"
                                    v-tooltip="{ content: 'Edit Account' }"
                                    :to="{
                                        name: 'accounts.edit',
                                        params: $route.params.id,
                                    }"
                                    custom
                                >
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fa fa-caret-left" aria-hidden="true" />
                                    </button>
                                </router-link>

                                <button
                                    v-if="!account.isSuspended"
                                    id="top-mobile-reset-pwd-btn"
                                    v-tooltip="{ content: 'Reset Password' }"
                                    class="btn btn-default mainBtn"
                                    aria-label="Reset Password"
                                    @click.prevent="openResetPasswordModal(account, 'account')"
                                >
                                    <i class="fa fa-key" aria-hidden="true" />
                                </button>

                                <router-link v-slot="{ navigate }" v-tooltip="{ content: 'Back' }" :to="{ name: 'accounts.index' }" custom>
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fa fa-caret-left" aria-hidden="true" />
                                    </button>
                                </router-link>
                            </div>
                        </div>
                    </template>
                    <template #content>
                        <div class="col-xs-12 col-md-12 viewFullDetails">
                            <div>
                                <div class="form-group">
                                    <label class="control-label text-uppercase">ORGANIZATION</label>
                                    <p class="form-control-static">
                                        {{ account.organisationName }}
                                    </p>
                                </div>
                            </div>
                            <div class="flex">
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">FULL NAME OF SUPER ADMIN</label>
                                        <p class="form-control-static">
                                            {{ account.adminTeacherAccount.displayName }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">EMAIL</label>
                                        <p class="form-control-static">
                                            {{ account.adminTeacherAccount.email }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">JOB DESIGNATION</label>
                                        <p class="form-control-static">
                                            {{ account.adminTeacherAccount.jobDescription }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">INFLUENCER LEVEL</label>
                                        <p class="form-control-static">
                                            {{ getInfluencerLevelLabel(account.adminTeacherAccount.influencerLevel) }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <!-- <div>
                <div class="form-group">
                  <label class="control-label text-uppercase">SUB-DOMAIN</label>
                  <p class="form-control-static">{{ account.accountName }}</p>
                </div>
              </div> -->

                            <div class="flex">
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">SEGMENT</label>
                                        <p class="form-control-static">
                                            {{ account.adminTeacherAccount.segment }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase"> FIELD OF DISCIPLINE </label>
                                        <p class="form-control-static">
                                            {{ account.adminTeacherAccount.fieldOfDiscipline }}
                                            <template v-if="account.adminTeacherAccount.fieldOfDiscipline == 'Others'">
                                                -
                                                {{ account.adminTeacherAccount.otherFieldOfDiscipline }}
                                            </template>
                                        </p>
                                    </div>
                                </div>

                                <!-- <div class="col-xs-12 col-md-3">
                  <div class="form-group">
                    <label class="control-label text-uppercase"
                      >ID IN CLUSTER</label
                    >
                    <p class="form-control-static"></p>
                  </div>
                </div> -->

                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">STATUS</label>
                                        <p class="form-control-static">
                                            <template v-if="!account.isSuspended">
                                                <i class="fa fa-circle activeCircle marginRight8" aria-hidden="true" />Active
                                            </template>
                                            <template v-else> <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Suspended </template>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">LMS</label>
                                        <p class="form-control-static">
                                            {{ account.lms }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">TYPE</label>
                                        <p class="form-control-static">
                                            {{ account.type }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase"> PAYMENT METHOD </label>
                                        <p class="form-control-static">
                                            {{ account.paymentMethod }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase"> CONTRACT START DATE </label>
                                        <p class="form-control-static">
                                            {{ convertToReadableDate(account.startDate, 'DD MMM YYYY').date }}
                                            ({{ convertToReadableDate(account.startDate, 'DD MMM YYYY').current_timezone }})
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase"> CONTRACT END DATE </label>
                                        <p class="form-control-static">
                                            {{ convertToReadableDate(account.expiryDate, 'DD MMM YYYY').date }}
                                            ({{ convertToReadableDate(account.expiryDate, 'DD MMM YYYY').current_timezone }})
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-xs-12 flex">
                <div
                  class="col-xs-12 col-md-6"
                  v-if="
                    account.paymentMethod == 'Student' && account.type == 'Paid'
                  "
                >
                  <div class="form-group">
                    <label class="control-label text-uppercase">PRICING</label>
                    <p class="form-control-static">
                      {{ account.paymentPlans[0].currency }}
                      {{ account.paymentPlans[0].price }}
                    </p>
                  </div>
                </div>
                <div class="col-xs-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label text-uppercase"
                      >PLAN PERIOD</label
                    >
                    <p
                      class="form-control-static"
                      v-if="
                        !(
                          account.paymentMethod == 'Student' &&
                          account.type == 'Paid'
                        )
                      "
                    >
                      {{
                        convertToReadableDate(account.startDate, "DD MMM YYYY")
                          .date
                      }}
                      ({{
                        convertToReadableDate(account.startDate, "DD MMM YYYY")
                          .current_timezone
                      }}) to
                      {{
                        convertToReadableDate(account.expiryDate, "DD MMM YYYY")
                          .date
                      }}
                      ({{
                        convertToReadableDate(account.expiryDate, "DD MMM YYYY")
                          .current_timezone
                      }})
                    </p>
                    <p class="form-control-static" v-else>
                      {{ account.paymentPlans[0].licensePeriod }} month/s
                    </p>
                  </div>
                </div>
              </div> -->

                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase"> CREATED BY </label>
                                        <p class="form-control-static">
                                            {{ account.createBy }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase"> CREATED ON </label>
                                        <p class="form-control-static">
                                            <template v-if="account.dateCreated">
                                                {{ convertToReadableDate(account.dateCreated, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(account.dateCreated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>

                                <template v-if="account.paymentMethod != 'Student-Paid'">
                                    <div class="col-xs-12 col-md-3">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase"> ACTIVATED ON </label>
                                            <p class="form-control-static">
                                                <template v-if="account.dateActivated">
                                                    {{ convertToReadableDate(account.dateActivated, 'DD MMM YYYY, hh:mm a').date }}
                                                    ({{ convertToReadableDate(account.dateActivated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                </template>
                                                <template v-else> - </template>
                                            </p>
                                        </div>
                                    </div>

                                    <div class="col-xs-12 col-md-3">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase"> EXPIRES ON </label>
                                            <p class="form-control-static">
                                                <template v-if="account.expiryDate">
                                                    {{ convertToReadableDate(account.expiryDate, 'DD MMM YYYY').date }}
                                                    ({{ convertToReadableDate(account.expiryDate, 'DD MMM YYYY').current_timezone }})
                                                </template>
                                                <template v-else> - </template>
                                            </p>
                                        </div>
                                    </div>
                                </template>
                            </div>

                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase"> ACCOUNT LAST LOGIN </label>
                                        <p class="form-control-static">
                                            <template v-if="account.adminTeacherAccount.dateLastLogin">
                                                {{ convertToReadableDate(account.adminTeacherAccount.dateLastLogin, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{
                                                    convertToReadableDate(account.adminTeacherAccount.dateLastLogin, 'DD MMM YYYY, hh:mm a').current_timezone
                                                }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase"> ACCOUNT LAST MODIFIED </label>
                                        <p class="form-control-static">
                                            <template v-if="account.lastModified">
                                                {{ convertToReadableDate(account.lastModified, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(account.lastModified, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">VERSION</label>
                                        <p class="form-control-static">
                                            <template v-if="account.version == 'full'"> Full </template>
                                            <template v-else> Lite </template>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="(account.type == 'Paid' || account.type == 'Paid Trial') && account.paymentMethod == 'Student-Paid'"
                                class="col-xs-12 marginTop30"
                            >
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>PLAN</th>
                                                <th>PRICE</th>
                                                <th>PLAN PERIOD</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(plan, idx) in account.paymentPlans" :key="idx">
                                                <tr>
                                                    <template v-if="plan.plan">
                                                        <td>
                                                            {{ plan.plan }}
                                                        </td>
                                                        <td>
                                                            {{ plan.price }}
                                                        </td>
                                                        <td>
                                                            <div class="row" style="text-align: center; font-size: 20px; line-height: 30px">
                                                                {{ plan.planPeriod }}
                                                            </div>
                                                        </td>
                                                    </template>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </template>
                </kr-panel>

                <div class="viewDropdown flexRight" @click.prevent="toggle">
                    <div class="dropdown col-xs-2">
                        <button
                            id="viewDropdownMenuButton"
                            class="mainBtn width100 flexSpaceBetween align-items"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            aria-label="More Actions"
                        >
                            <span class="marginRight10"> More Actions </span>
                            <span>
                                <i
                                    :class="{
                                        'fa fa-caret-up': isOpened,
                                        'fa fa-caret-down': !isOpened,
                                    }"
                                />
                            </span>
                        </button>

                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="viewDropdownMenuButton">
                            <ul>
                                <li v-if="!account.isSuspended">
                                    <a
                                        v-tooltip="'Suspend Account'"
                                        class="danger-state"
                                        href="#"
                                        aria-labelledby="Suspend Account"
                                        aria-label="Suspend Account"
                                        @click.prevent="openSuspendModal()"
                                    >
                                        <span> <i class="fa fa-archive marginRight8" aria-hidden="true" />Suspend Account </span>
                                    </a>
                                </li>

                                <li v-else>
                                    <a
                                        v-tooltip="'Reinstate'"
                                        class="primary-state"
                                        href="#"
                                        aria-labelledby="Reinstate"
                                        aria-label="Reinstate"
                                        @click.prevent="openSuspendModal()"
                                    >
                                        <span> <i class="fa fa-refresh marginRight8" aria-hidden="true" />Reinstate </span>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <!-- <div class="dropdown-menu" aria-labelledby="viewDropdownMenuButton">
              <button
                id="dropdown-suspend-account-btn"
                class="btn btn-danger"
                v-if="!account.isSuspended"
                @click.prevent="openSuspendModal()"
                aria-label="Suspend Account"
              >
                <i class="fa fa-archive"></i>&nbsp;Suspend Account
              </button>
              <button
                id="dropdown-reinstate-btn"
                class="btn btn-success"
                v-else
                @click.prevent="openSuspendModal()"
                aria-label="Reinstate"
              >
                <i class="fa fa-refresh"></i>&nbsp;Reinstate
              </button>
            </div> -->
                    </div>
                </div>

                <kr-panel v-if="false" :with-footer="false" class="marginTop30">
                    <template #title>
                        <div class="col-xs-6">ESTIMATED USAGE</div>
                        <div class="col-xs-6 textAlignRight">
                            <div class="hidden-xs">
                                <router-link
                                    v-slot="{ navigate }"
                                    :to="{
                                        name: 'accounts.usage.edit',
                                        params: $route.params.id,
                                    }"
                                    custom
                                >
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                                    </button>
                                </router-link>
                            </div>
                            <div class="hidden-md hidden-lg">
                                <router-link
                                    v-slot="{ navigate }"
                                    v-tooltip="{
                                        content: 'Edit Estimated Usage',
                                    }"
                                    :to="{
                                        name: 'accounts.usage.edit',
                                        params: $route.params.id,
                                    }"
                                    custom
                                >
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fa fa-pencil-square-o" aria-hidden="true" />
                                    </button>
                                </router-link>
                            </div>
                        </div>
                    </template>

                    <template #content>
                        <div class="col-xs-12 col-md-12 viewFullDetails">
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase"> NO OF FACULTY </label>
                                        <p class="form-control-static">
                                            {{ account.estimatedUsage.noOfFaculty }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase"> NO OF STUDENT LICENSES </label>
                                        <p class="form-control-static">
                                            {{ account.estimatedUsage.noOfStudentLicences }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase"> ACTIVITY INTERESTS </label>
                                        <p class="form-control-static">
                                            {{ account.estimatedUsage.activityInterests }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase"> USAGE TYPE </label>
                                        <p class="form-control-static">
                                            {{ account.estimatedUsage.usageType }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase"> EXPANSION POTENTIAL </label>
                                        <p class="form-control-static">
                                            {{ account.estimatedUsage.expansionPotential }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase"> COURSE START DATE </label>
                                        <p class="form-control-static">
                                            {{ account.estimatedUsage.courseStartDate }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </kr-panel>

                <kr-panel v-if="false" :with-footer="false">
                    <template #title>
                        <div class="col-xs-6">ONBOARDING</div>
                        <div class="col-xs-6 textAlignRight">
                            <div class="hidden-xs">
                                <router-link
                                    v-slot="{ navigate }"
                                    :to="{
                                        name: 'accounts.onboard.edit',
                                        params: $route.params.id,
                                    }"
                                    custom
                                >
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                                    </button>
                                </router-link>
                            </div>
                            <div class="hidden-md hidden-lg">
                                <router-link
                                    v-slot="{ navigate }"
                                    v-tooltip="{
                                        content: 'Edit Estimated Usage',
                                    }"
                                    :to="{
                                        name: 'accounts.onboard.edit',
                                        params: $route.params.id,
                                    }"
                                    custom
                                >
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fa fa-pencil-square-o" aria-hidden="true" />
                                    </button>
                                </router-link>
                            </div>
                        </div>
                    </template>

                    <template #content>
                        <div class="col-xs-12 col-md-12 viewFullDetails">
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase"> ONBOARDING </label>
                                        <p class="form-control-static">
                                            {{ account.onboarding.onboarding }}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase"> DATE & TIME </label>
                                        <p class="form-control-static">
                                            <template v-if="account.onboarding.date">
                                                {{ convertToReadableDate(account.onboarding.date, 'DD MMM YYYY').date }}
                                                ({{ convertToReadableDate(account.onboarding.date, 'DD MMM YYYY').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase"> ASSIGNED CUSTOMER ADVISOR </label>
                                        <p class="form-control-static">
                                            {{ account.onboarding.customerAdvisor }}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">LMS</label>
                                        <p class="form-control-static">
                                            {{ account.onboarding.lms }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div class="form-group">
                                    <label class="control-label text-uppercase"> SCHOOL YEAR </label>
                                    <p class="form-control-static">
                                        {{ account.onboarding.schoolYear }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </template>
                </kr-panel>

                <kr-panel v-if="false" :with-footer="false">
                    <template #title>
                        <div class="col-xs-6">NOTES</div>
                        <div class="col-xs-6 textAlignRight">
                            <div class="hidden-xs">
                                <router-link
                                    v-slot="{ navigate }"
                                    :to="{
                                        name: 'accounts.notes.create',
                                        params: $route.params.id,
                                    }"
                                    custom
                                >
                                    <button class="btn btn-primary" @click="navigate"><i class="fa fa-plus marginRight8" aria-label="Add" />New Note</button>
                                </router-link>
                            </div>
                            <div class="hidden-md hidden-lg">
                                <router-link
                                    v-slot="{ navigate }"
                                    v-tooltip="{
                                        content: 'Add New Note',
                                    }"
                                    :to="{
                                        name: 'accounts.notes.create',
                                        params: $route.params.id,
                                    }"
                                    custom
                                >
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fa fa-plus" aria-hidden="true" />
                                    </button>
                                </router-link>
                            </div>
                        </div>
                    </template>

                    <template #content>
                        <template v-for="note in notes" :key="note.uuid">
                            <div class="col-xs-12 col-md-12 viewFullDetails">
                                <div class="col-xs-12 flex">
                                    <div class="col-xs-12 col-md-3">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">TYPE</label>
                                            <p class="form-control-static">
                                                {{ note.type }}
                                            </p>
                                        </div>
                                    </div>

                                    <div v-if="['Customer', 'Onboarding'].includes(note.type)" class="col-xs-12 col-md-3">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">
                                                <template v-if="note.type == 'Onboarding'"> Onboarding </template>
                                                <template v-else> Call </template>
                                                Date
                                            </label>
                                            <p class="form-control-static">
                                                <template v-if="note.date">
                                                    {{ convertToReadableDate(note.date, 'DD MMM YYYY').date }}
                                                    ({{ convertToReadableDate(note.date, 'DD MMM YYYY').current_timezone }})
                                                </template>
                                                <template v-else> - </template>
                                            </p>
                                        </div>
                                    </div>

                                    <div v-if="note.type != 'Basic'" class="col-xs-12 col-md-3">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase"> ASSIGNED CUSTOMER ADVISOR </label>
                                            <p class="form-control-static">
                                                {{ note.customerAdvisor }}
                                            </p>
                                        </div>
                                    </div>

                                    <div v-if="note.type == 'Onboarding'" class="col-xs-12 col-md-3">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase"> SCHOOL YEAR </label>
                                            <p class="form-control-static">
                                                {{ note.schoolYear }}
                                            </p>
                                        </div>
                                    </div>

                                    <div v-if="note.type == 'Customer'" class="col-xs-12 col-md-3">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase"> CALL TYPE </label>
                                            <p class="form-control-static">
                                                {{ note.callType }}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="note.type == 'Customer'">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase"> CALL STATUS </label>
                                        <p class="form-control-static">
                                            {{ note.status }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="noteDiv marginTop20">
                                <div class="alert alert-warning">
                                    <div style="white-space: prewrap" v-html="note.notes" />
                                </div>
                                <div class="flex">
                                    <div class="col-xs-6 col-md-6">
                                        {{ note.createBy }} at
                                        {{ convertToReadableDate(note.dateCreated, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(note.dateCreated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </div>
                                    <div class="col-xs-6 textAlignRight actions">
                                        <router-link
                                            v-slot="{ navigate }"
                                            :to="{
                                                name: 'accounts.notes.edit',
                                                params: {
                                                    id: note.uuid,
                                                    account_id: $route.params.id,
                                                },
                                            }"
                                            custom
                                        >
                                            <button class="btn btn-default" @click="navigate">
                                                <i class="fa fa-pencil-square-o" aria-hidden="true" />
                                            </button>
                                        </router-link>

                                        <button class="btn btn-danger" aria-label="Delete" @click="openDeleteNoteModal(note)">
                                            <i class="fa fa-trash-o" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-if="notes.length == 0">
                            <div>This account currently has no notes.</div>
                        </template>
                    </template>
                </kr-panel>
            </div>

            <div id="superadmin" class="tab-pane fade in" :class="{ active: tab == 'superadmin' }">
                <kr-panel :with-footer="false" class="overflowXinherit">
                    <template #title> SUPER ADMINS </template>

                    <template #content>
                        <kr-search
                            name="superadmins_listing"
                            :url="{
                                url: '/accounts/' + $route.params.id + '/account-users',
                            }"
                            :columns="{
                                name: 'display:FULL NAME|sortable:false|width:22%',
                                email: 'display:EMAIL|sortable:false|width:22%|mobile:hide',
                                isSuspended: 'display:STATUS|sortable:false|width:130px|mobile:hide',
                                dateLastLogin: 'display:LAST LOGIN|sortable:false|width:256px|mobile:hide',
                                action: 'display:|sortable:false|width:132px',
                            }"
                            :options="{
                                suspended_field: true,
                                params: { role: '2' },
                                search_field: true,
                                action_column: false,
                            }"
                            @retrieve-data="getSuperAdminSearchData"
                        >
                            <template #upper-right>
                                <div class="hidden-xs hidden-sm">
                                    <router-link
                                        v-slot="{ navigate }"
                                        :to="{
                                            name: 'accounts.superadmins.create',
                                            params: {
                                                account_id: account.uuid,
                                            },
                                        }"
                                        custom
                                    >
                                        <button class="btn btn-primary" @click="navigate">
                                            <i class="fa fa-plus marginRight8" aria-label="Add" />New Super Admin
                                        </button>
                                    </router-link>
                                    <router-link v-slot="{ navigate }" :to="{ name: 'accounts.index' }" custom>
                                        <button class="btn btn-default" @click="navigate">
                                            <i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back
                                        </button>
                                    </router-link>
                                </div>
                                <div class="hidden-md hidden-lg">
                                    <router-link
                                        id="top-mobile-add-superadmin-btn"
                                        :to="{
                                            name: 'accounts.superadmins.create',
                                            params: {
                                                account_id: account.uuid,
                                            },
                                        }"
                                        tag="button"
                                        class="btn btn-primary mainBtn"
                                        aria-label="New Super Admin"
                                    >
                                        <i class="fa fa-plus" aria-hidden="true" />
                                    </router-link>
                                    <router-link
                                        id="top-mobile-back-superadmin-btn"
                                        :to="{ name: 'accounts.index' }"
                                        tag="button"
                                        class="btn btn-default mainBtn"
                                        exact
                                        aria-label="Back"
                                    >
                                        <i class="fa fa-caret-left" aria-hidden="true" />
                                    </router-link>
                                </div>
                            </template>

                            <template #top-display-from-to="props">
                                <div class="displayNo">
                                    {{ props.model.from }} - {{ props.model.to }} of
                                    {{ props.model.meta.paginator.total }}
                                </div>
                            </template>

                            <template #table-title="props">
                                <th colspan="6">
                                    <template v-if="superAdminSearchData.query.isSuspended == '0'"> Active </template>
                                    <template v-if="superAdminSearchData.query.isSuspended == '1'"> Suspended </template>
                                    <template v-if="superAdminSearchData.query.isSuspended == '*'"> All </template>
                                    Super Admins ( {{ props.model.from }} - {{ props.model.to }} of {{ props.model.meta.paginator.total }} )
                                </th>
                            </template>

                            <template #bottom-display-from-to="props">
                                <div class="displayNo">
                                    {{ props.model.from }} - {{ props.model.to }} of
                                    {{ props.model.meta.paginator.total }}
                                </div>
                            </template>

                            <template #cdata="props">
                                <td>
                                    <router-link
                                        v-tooltip="{
                                            content: 'View Teacher',
                                        }"
                                        :to="{
                                            name: 'accounts.superadmins.view',
                                            params: { id: props.model.uuid },
                                        }"
                                        class="link"
                                    >
                                        {{ props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : '') }}
                                    </router-link>
                                </td>
                                <td class="hidden-sm hidden-xs">
                                    {{ props.model.email }}
                                </td>
                                <td class="hidden-xs hidden-sm">
                                    <template v-if="!props.model.isSuspended">
                                        <i class="fa fa-circle activeCircle marginRight8" aria-hidden="true" />Active
                                    </template>
                                    <template v-else> <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Suspended </template>
                                </td>
                                <td class="hidden-xs hidden-sm">
                                    <template v-if="props.model.dateLastLogin">
                                        {{ convertToReadableDate(props.model.dateLastLogin, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.dateLastLogin, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>
                                    <template v-else> - </template>
                                </td>
                                <td>
                                    <div class="dropdown">
                                        <button
                                            id="viewDropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            aria-label="Actions"
                                        >
                                            Actions<i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                            <ul>
                                                <li>
                                                    <router-link
                                                        v-tooltip="'View Super Admin'"
                                                        class="primary-state"
                                                        :to="{
                                                            name: 'accounts.superadmins.view',
                                                            params: {
                                                                id: props.model.uuid,
                                                            },
                                                        }"
                                                        aria-label="View Super Admin"
                                                    >
                                                        <span> <i class="fas fa-eye marginRight8" aria-hidden="true" />View Super Admin </span>
                                                    </router-link>
                                                </li>

                                                <template v-if="!props.model.isSuspended">
                                                    <li>
                                                        <a
                                                            v-tooltip="'Reset Password'"
                                                            class="primary-state"
                                                            href="#"
                                                            aria-label="Reset Password"
                                                            @click.prevent="openResetPasswordModal(props.model, 'teacher')"
                                                        >
                                                            <span> <i class="fa fa-key marginRight8" aria-hidden="true" />Reset Password </span>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a
                                                            v-tooltip="'Impersonate User'"
                                                            class="primary-state"
                                                            href="#"
                                                            aria-label="Impersonate User"
                                                            @click.prevent="openImpersonationModal(props.model)"
                                                        >
                                                            <span> <i class="fa fa-user marginRight8" aria-hidden="true" />Impersonate User </span>
                                                        </a>
                                                    </li>
                                                    <li v-if="superAdminSearchData.meta.paginator.total > 1">
                                                        <a
                                                            v-tooltip="'Remove Superadmin role'"
                                                            class="primary-state"
                                                            href="#"
                                                            aria-label="Remove Superadmin role"
                                                            @click.prevent="openRemoveSuperAdminConfirmationModal(props.model)"
                                                        >
                                                            <span> <i class="fa fa-trash marginRight8" aria-hidden="true" />Remove Super Admin role </span>
                                                        </a>
                                                    </li>
                                                </template>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </template>
                        </kr-search>
                    </template>
                </kr-panel>
            </div>
            <div id="teacher" class="tab-pane fade in" :class="{ active: tab == 'teacher' }">
                <kr-panel :with-footer="false" class="overflowXinherit">
                    <template #title> TEACHERS </template>

                    <template #content>
                        <kr-search
                            name="teachers_listing"
                            :url="{
                                url: '/accounts/' + $route.params.id + '/account-users',
                            }"
                            :columns="{
                                name: 'display:FULL NAME|sortable:false|width:22%',
                                role: 'display:ROLE|sortable:false|width:142px|mobile:hide',
                                email: 'display:EMAIL|sortable:false|width:22%|mobile:hide',
                                isSuspended: 'display:STATUS|sortable:false|width:130px|mobile:hide',
                                dateLastLogin: 'display:LAST LOGIN|sortable:false|width:256px|mobile:hide',
                                action: 'display:|sortable:false|width:132px',
                            }"
                            :options="{
                                suspended_field: true,
                                params: { role: '3' },
                                search_field: true,
                                action_column: false,
                            }"
                            @retrieve-data="getTeacherSearchData"
                        >
                            <template #upper-right>
                                <div class="hidden-xs hidden-sm">
                                    <router-link
                                        id="top-add-teacher-btn"
                                        :to="{
                                            name: 'accounts.teachers.create',
                                            params: {
                                                account_id: account.uuid,
                                            },
                                        }"
                                        tag="button"
                                        class="btn btn-primary mainBtn"
                                        aria-label="New Teacher"
                                    >
                                        <i class="fa fa-plus marginRight8" aria-hidden="true" />New Teacher
                                    </router-link>
                                    <router-link
                                        id="top-back-teacher-btn"
                                        :to="{ name: 'accounts.index' }"
                                        tag="button"
                                        class="btn btn-default mainBtn"
                                        exact
                                        aria-label="Back"
                                    >
                                        <i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back
                                    </router-link>
                                </div>
                                <div class="hidden-md hidden-lg">
                                    <router-link
                                        id="top-mobile-add-teacher-btn"
                                        :to="{
                                            name: 'accounts.teachers.create',
                                            params: {
                                                account_id: account.uuid,
                                            },
                                        }"
                                        tag="button"
                                        class="btn btn-primary mainBtn"
                                        aria-label="New Teacher"
                                    >
                                        <i class="fa fa-plus" aria-hidden="true" />
                                    </router-link>
                                    <router-link
                                        id="top-mobile-back-teacher-btn"
                                        :to="{ name: 'accounts.index' }"
                                        tag="button"
                                        class="btn btn-default mainBtn"
                                        exact
                                        aria-label="Back"
                                    >
                                        <i class="fa fa-caret-left" aria-hidden="true" />
                                    </router-link>
                                </div>
                            </template>

                            <template #top-display-from-to="props">
                                <div class="displayNo">
                                    {{ props.model.from }} - {{ props.model.to }} of
                                    {{ props.model.meta.paginator.total }}
                                </div>
                            </template>

                            <template #table-title="props">
                                <th colspan="6">
                                    <template v-if="teacherSearchData.query.isSuspended == '0'"> Active </template>
                                    <template v-if="teacherSearchData.query.isSuspended == '1'"> Suspended </template>
                                    <template v-if="teacherSearchData.query.isSuspended == '*'"> All </template>
                                    Teachers ( {{ props.model.from }} - {{ props.model.to }} of {{ props.model.meta.paginator.total }} )
                                </th>
                            </template>

                            <template #bottom-display-from-to="props">
                                <div class="displayNo">
                                    {{ props.model.from }} - {{ props.model.to }} of
                                    {{ props.model.meta.paginator.total }}
                                </div>
                            </template>

                            <template #cdata="props">
                                <td>
                                    <router-link
                                        v-tooltip="{
                                            content: 'View Teacher',
                                        }"
                                        :to="{
                                            name: 'accounts.teachers.view',
                                            params: { id: props.model.uuid },
                                        }"
                                        class="link"
                                    >
                                        {{ props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : '') }}
                                    </router-link>
                                </td>
                                <td class="hidden-sm hidden-xs">
                                    <template v-if="props.model.role == 'Super Admin'"> Super Admin </template>
                                    <template v-else-if="props.model.canAddTeacher"> Admin Teacher </template>
                                    <template v-else> Teacher </template>
                                </td>
                                <td class="hidden-sm hidden-xs">
                                    {{ props.model.email }}
                                </td>
                                <td class="hidden-xs hidden-sm">
                                    <template v-if="!props.model.isSuspended">
                                        <i class="fa fa-circle activeCircle marginRight8" aria-hidden="true" />Active
                                    </template>
                                    <template v-else> <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Suspended </template>
                                </td>
                                <td class="hidden-xs hidden-sm">
                                    <template v-if="props.model.dateLastLogin">
                                        {{ convertToReadableDate(props.model.dateLastLogin, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.dateLastLogin, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>
                                    <template v-else> - </template>
                                </td>
                                <td>
                                    <div class="dropdown">
                                        <button
                                            id="viewDropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            aria-label="Actions"
                                        >
                                            Actions<i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                            <ul>
                                                <li>
                                                    <router-link
                                                        v-tooltip="'View Teacher'"
                                                        class="primary-state"
                                                        :to="{
                                                            name: 'accounts.teachers.view',
                                                            params: {
                                                                id: props.model.uuid,
                                                            },
                                                        }"
                                                        aria-label="View Teacher"
                                                    >
                                                        <span> <i class="fas fa-eye marginRight8" aria-hidden="true" />View Teacher </span>
                                                    </router-link>
                                                </li>

                                                <template v-if="!props.model.isSuspended">
                                                    <li>
                                                        <a
                                                            v-tooltip="'Reset Password'"
                                                            class="primary-state"
                                                            href="#"
                                                            aria-label="Reset Password"
                                                            @click.prevent="openResetPasswordModal(props.model, 'teacher')"
                                                        >
                                                            <span> <i class="fa fa-key marginRight8" aria-hidden="true" />Reset Password </span>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a
                                                            v-tooltip="'Impersonate User'"
                                                            class="primary-state"
                                                            href="#"
                                                            aria-label="Impersonate User"
                                                            @click.prevent="openImpersonationModal(props.model)"
                                                        >
                                                            <span> <i class="fa fa-user marginRight8" aria-hidden="true" />Impersonate User </span>
                                                        </a>
                                                    </li>
                                                </template>
                                            </ul>
                                        </div>
                                    </div>

                                    <!-- <div class="buttonGroupInline hidden-xs hidden-sm">
                    <router-link
                      id="view-teacher-btn"
                      tag="button"
                      v-tooltip="{
                        content: 'View Teacher',
                      }"
                      :to="{
                        name: 'accounts.teachers.view',
                        params: { id: props.model.uuid },
                      }"
                      class="btn btn-primary subBtn"
                      aria-label="View"
                    >
                      <i class="fa fa-eye"></i>&nbsp;View
                    </router-link>
                    <router-link
                      id="edit-teacher-btn"
                      tag="button"
                      v-tooltip="{
                        content: 'Edit',
                      }"
                      :to="{
                        name: 'accounts.teachers.edit',
                        params: { id: props.model.uuid },
                      }"
                      class="btn btn-default subBtn"
                    >
                      <i class="fa fa-pencil-square-o"></i>&nbsp;Edit
                    </router-link>
                    <button
                      id="reset-pwd-teacher-btn"
                      v-if="!props.model.isSuspended"
                      class="btn btn-default subBtn"
                      @click.prevent="
                        openResetPasswordModal(props.model, 'teacher')
                      "
                      aria-label="Reset Password"
                    >
                      <i class="fa fa-key"></i>
                    </button>
                    <button
                      id="impersonate-btn"
                      class="btn btn-default subBtn"
                      v-if="!props.model.isSuspended"
                      @click.prevent="openImpersonationModal(props.model)"
                      v-tooltip="{ content: 'Impersonate User' }"
                      aria-label="Impersonate User"
                    >
                      <i class="fa fa-user"></i>&nbsp; Impersonate User
                    </button>
                  </div> -->
                                    <!-- <div class="hidden-md hidden-lg">
                    <router-link
                      id="mobile-view-teacher-btn"
                      tag="button"
                      v-tooltip="{
                        content: 'View Teacher',
                      }"
                      :to="{
                        name: 'accounts.teachers.view',
                        params: { id: props.model.uuid },
                      }"
                      class="btn btn-primary mainBtn"
                      aria-label="View"
                    >
                      <i class="fa fa-eye"></i>
                    </router-link>
                    <router-link
                      id="mobile-edit-teacher-btn"
                      tag="button"
                      v-tooltip="{
                        content: 'Edit',
                      }"
                      :to="{
                        name: 'accounts.teachers.edit',
                        params: { id: props.model.uuid },
                      }"
                      class="btn btn-default mainBtn"
                    >
                      <i class="fa fa-pencil-square-o"></i>
                    </router-link>
                    <button
                      id="mobile-reset-pwd-teacher-btn"
                      v-if="!props.model.isSuspended"
                      class="btn btn-default mainBtn"
                      @click.prevent="
                        openResetPasswordModal(props.model, 'teacher')
                      "
                      v-tooltip="{ content: 'Reset Password' }"
                      aria-label="Reset Password"
                    >
                      <i class="fa fa-key"></i>
                    </button>
                    <button
                      id="impersonate-btn"
                      class="btn btn-default mainBtn"
                      v-if="!props.model.isSuspended"
                      @click.prevent="openImpersonationModal(props.model)"
                      v-tooltip="{ content: 'Impersonate User' }"
                      aria-label="Impersonate User"
                    >
                      <i class="fa fa-user"></i>
                    </button>
                  </div> -->
                                </td>
                            </template>
                        </kr-search>

                        <!-- <div class="table-responsive">
              <table class="table">
                <thead>
                  <th width="40%">NAME</th>
                  <th width="20%">EMAIL</th>
                  <td width="40%"></td>
                </thead>
                <tbody>
                  <tr
                    v-if="
                      account.teachers.length +
                        account.adminTeacherAccounts.length ==
                        0
                    "
                  >
                    <td colspan="3" style="text-align:center">
                      NO TEACHER(S)
                    </td>
                  </tr>
                  <tr v-for="teacher in account.adminTeacherAccounts">
                    <td>
                      {{ teacher.displayName }}
                      <span class="label label-primary">Admin Teacher</span>
                    </td>
                    <td>{{ teacher.email }}</td>
                    <td>
                      <router-link
                        tag="button"
                        v-tooltip="{
                          content: 'Edit',
                        }"
                        v-if="!teacher.isTrashed"
                        :to="{
                          name: 'accounts.teachers.edit',
                          params: { id: teacher.uuid },
                        }"
                        class="btn btn-secondary  btn-icon hidden-xs hidden-sm"
                        ><i class="fa fa-pencil-square-o" aria-hidden="true"></i
                      ></router-link>
                      <router-link
                        v-if="!teacher.isTrashed"
                        :to="{
                          name: 'accounts.teachers.edit',
                          params: { id: teacher.uuid },
                        }"
                        class="btn btn-secondary btn-action hidden-md hidden-lg"
                        style="width:100%;line-height:1;padding:10px;"
                        ><i
                          class="fa fa-pencil-square-o"
                          aria-hidden="true"
                        ></i>
                        Edit</router-link
                      >
                      <router-link
                        tag="button"
                        v-tooltip="{
                          content: 'View',
                        }"
                        v-if="!teacher.isTrashed"
                        :to="{
                          name: 'accounts.teachers.view',
                          params: { id: teacher.uuid },
                        }"
                        class="btn btn-secondary btn-icon hidden-xs hidden-sm"
                        ><i class="fa fa-eye" aria-hidden="true"></i>
                      </router-link>
                      <router-link
                        v-if="!teacher.isTrashed"
                        :to="{
                          name: 'accounts.teachers.view',
                          teacher: { id: teacher.uuid },
                        }"
                        class="btn btn-secondary btn-action hidden-md hidden-lg"
                        style="width:100%;line-height:1;padding:10px;"
                        ><i class="fa fa-eye" aria-hidden="true"></i>
                        View</router-link
                      >
                    </td>
                  </tr>
                  <tr v-for="teacher in account.teachers">
                    <td>{{ teacher.displayName }}</td>
                    <td>{{ teacher.email }}</td>
                    <td>
                      <router-link
                        tag="button"
                        v-tooltip="{
                          content: 'Edit',
                        }"
                        v-if="!teacher.isTrashed"
                        :to="{
                          name: 'accounts.teachers.edit',
                          params: { id: teacher.uuid },
                        }"
                        class="btn btn-secondary  btn-icon hidden-xs hidden-sm"
                        ><i class="fa fa-pencil-square-o" aria-hidden="true"></i
                      ></router-link>
                      <router-link
                        v-if="!teacher.isTrashed"
                        :to="{
                          name: 'accounts.teachers.edit',
                          params: { id: teacher.uuid },
                        }"
                        class="btn btn-secondary btn-action hidden-md hidden-lg"
                        style="width:100%;line-height:1;padding:10px;"
                        ><i
                          class="fa fa-pencil-square-o"
                          aria-hidden="true"
                        ></i>
                        Edit</router-link
                      >
                      <router-link
                        tag="button"
                        v-tooltip="{
                          content: 'View',
                        }"
                        v-if="!teacher.isTrashed"
                        :to="{
                          name: 'accounts.teachers.view',
                          params: { id: teacher.uuid },
                        }"
                        class="btn btn-secondary btn-icon hidden-xs hidden-sm"
                        ><i class="fa fa-eye" aria-hidden="true"></i>
                      </router-link>
                      <router-link
                        v-if="!teacher.isTrashed"
                        :to="{
                          name: 'accounts.teachers.view',
                          teacher: { id: teacher.uuid },
                        }"
                        class="btn btn-secondary btn-action hidden-md hidden-lg"
                        style="width:100%;line-height:1;padding:10px;"
                        ><i class="fa fa-eye" aria-hidden="true"></i>
                        View</router-link
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> -->
                    </template>
                </kr-panel>
            </div>

            <div id="student" class="tab-pane fade in" :class="{ active: tab == 'student' }">
                <kr-panel :with-footer="false" class="overflowXinherit">
                    <template #title> STUDENTS </template>
                    <template #content>
                        <kr-search
                            ref="students_listing"
                            name="students_listing"
                            :url="{ url: `students` }"
                            :columns="{
                                displayName: 'display:FULL NAME|sortable:true|width:30%',
                                email: 'display:EMAIL|sortable:true|width:12%|mobile:hide',
                                organisationID: 'display:STUDENT ID|sortable:true|width:13%|mobile:hide',
                                status: 'display:STATUS|sortable:true|width:20%|mobile:hide',
                                origin: 'display:ORIGIN|sortable:true|width:20%|mobile:hide',
                                action: 'display:|sortable:false|width:132px',
                            }"
                            :options="{
                                per_page: 10,
                                query_fields: student_query_fields,
                                default_query_fields: {
                                    sort: 'dateCreated',
                                    order: 'desc',
                                },
                                search_history_id: 'accounts_students_index',
                                action_column: false,
                                newServerMode: {
                                    includes: ['q', 'isSuspended', 'sort', 'order', 'origin', 'courseUuid', 'status', 'enrolled'],
                                },
                            }"
                            @retrieve-data="getStudentSearchData"
                        >
                            <template #lower-left>
                                <div style="display: flex">
                                    <div class="marginLeft20">
                                        <div class="form-group displayContent">
                                            <div class="form-dropdown viewFilter marginLeft0">
                                                <select
                                                    v-model="student_query_fields.status"
                                                    class="form-control"
                                                    :aria-label="'Show ' + student_query_fields.status + ' status'"
                                                    @change="$refs['students_listing'].debounced_search(true)"
                                                >
                                                    <option value="">All Status</option>
                                                    <option value="Invite Sent">Invite Sent</option>
                                                    <option value="Not Yet Invited">Not Yet Invited</option>
                                                    <option v-if="![3].includes(paidSettings)" value="Active">Active</option>
                                                    <option v-if="[1, 3].includes(paidSettings)" value="Active (Not Yet Paid)">Active (Not Yet Paid)</option>
                                                    <option v-if="[1, 3].includes(paidSettings)" value="Active (Paid)">Active (Paid)</option>
                                                    <option v-if="[1, 3].includes(paidSettings)" value="Lapsed">Lapsed</option>
                                                    <option v-if="[1, 3].includes(paidSettings)" value="Expired">Expired</option>
                                                    <option value="Suspended">Suspended</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dropdown marginLeft20 new-custom-search-select">
                                        <button
                                            id="viewDropdownMenuButton"
                                            class="btn mainBtn"
                                            type="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            aria-label="Search Course"
                                        >
                                            <template v-if="course === 'all'"> All Enrollments </template>
                                            <template v-else-if="course === 'enrolled'"> Enrolled in any course </template>
                                            <template v-else-if="course === 'not enrolled'"> Not enrolled in any course </template>
                                            <template v-else>
                                                {{ course.code ? course.code + ' - ' + course.name : course.name }}
                                            </template>

                                            <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="viewDropdownMenuButton">
                                            <div class="form-group form-search has-feedback has-feedback-left">
                                                <input
                                                    v-model="studentSearchData.q"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Search..."
                                                    @keyup="searchCourseDropdown"
                                                />
                                                <i class="fa fa-search form-control-feedback" aria-hidden="true"></i>
                                            </div>

                                            <ul>
                                                <div
                                                    v-if="studentSearchData.q && (resultQuery.length / perPage).toFixed() >= 2 && resultQuery.length != 0"
                                                    class="padding10 paddingTop0 flexSpaceBetween align-items"
                                                >
                                                    <a
                                                        class="link noBg flexOnly align-items lineHeight1"
                                                        href="#"
                                                        :class="{
                                                            disabled: pageNumber == 0,
                                                        }"
                                                        @click="previous"
                                                    >
                                                        <i class="fa fa-angle-double-left marginRight8" aria-hidden="true"></i>Prev
                                                    </a>

                                                    <span class="lineHeight1">
                                                        Page
                                                        {{ pageNumber + 1 }} of
                                                        {{ (resultQuery.length / perPage).toFixed() }}
                                                    </span>

                                                    <a
                                                        class="link noBg flexOnly align-items lineHeight1"
                                                        href="#"
                                                        :class="{
                                                            disabled: pageNumber >= (resultQuery.length / perPage).toFixed() - 1,
                                                        }"
                                                        @click="next"
                                                    >
                                                        Next<i class="fa fa-angle-double-right marginLeft8" aria-hidden="true"></i>
                                                    </a>
                                                </div>

                                                <template v-if="studentSearchData.isSearching">
                                                    <template
                                                        v-for="course in resultQuery.slice(pageNumber * perPage, pageNumber * perPage + perPage)"
                                                        :key="course.uuid"
                                                    >
                                                        <li
                                                            @click="
                                                                $refs['students_listing'].debounced_search(true);
                                                                select(course);
                                                            "
                                                            v-html="courseHighlight(course.code ? course.code + ' - ' + course.name : course.name)"
                                                        ></li>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <li
                                                        key="allCourse"
                                                        @click="
                                                            select('all');
                                                            $refs['students_listing'].debounced_search(true);
                                                        "
                                                    >
                                                        All Enrollments
                                                    </li>
                                                    <li
                                                        key="enrolledCourse"
                                                        @click="
                                                            select('enrolled');
                                                            $refs['students_listing'].debounced_search(true);
                                                        "
                                                    >
                                                        Enrolled in any course
                                                    </li>
                                                    <li
                                                        key="notEnrolledCourse"
                                                        @click="
                                                            select('not enrolled');
                                                            $refs['students_listing'].debounced_search(true);
                                                        "
                                                    >
                                                        Not enrolled in any course
                                                    </li>

                                                    <label v-if="coursesList.length > 0" class="dropdownHeading">
                                                        Course (Showing 8 of
                                                        {{ coursesList.length }})
                                                    </label>
                                                    <template v-if="coursesList.length > 0">
                                                        <template v-for="course in coursesList.slice(0, 8)" :key="course.uuid">
                                                            <li
                                                                @click="
                                                                    select(course);
                                                                    $refs['students_listing'].debounced_search(true);
                                                                "
                                                            >
                                                                {{ course.code ? course.code + ' - ' + course.name : course.name }}
                                                            </li>
                                                        </template>
                                                    </template>
                                                </template>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="viewFilter floatLeft marginLeft20">
                                        <div class="form-group displayContent">
                                            <div class="form-dropdown viewFilter marginLeft0">
                                                <select
                                                    v-model="student_query_fields.origin"
                                                    class="form-control"
                                                    :aria-label="
                                                        student_query_fields.origin == ''
                                                            ? 'Show ' + student_query_fields.origin
                                                            : 'Show ' + student_query_fields.origin + ' origin'
                                                    "
                                                    @change="$refs['students_listing'].debounced_search(true)"
                                                >
                                                    <option value="">All Origins</option>
                                                    <option value="Express Sign-in">Express Sign-in</option>
                                                    <option value="LTI">LTI</option>
                                                    <option value="Email">Email</option>
                                                    <option value="Generic">Generic</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template #upper-right>
                                <button class="btn btn-primary" aria-label="Export" @click="downloadStudents">
                                    <i class="fa fa-download marginRight8" aria-hidden="true" />Export
                                </button>
                            </template>

                            <template #table-title="props">
                                <th colspan="8">
                                    <!-- <template v-if="searchData.query.isSuspended == '0'"
                    >Active</template
                  >
                  <template v-if="searchData.query.isSuspended == '1'"
                    >Suspended</template
                  >
                  <template v-if="searchData.query.isSuspended == '*'"
                    >All</template
                  > -->
                                    Students
                                    <template v-if="course">
                                        in
                                        {{ course.code ? course.code + ' - ' + course.name : course.name }}
                                    </template>
                                    &nbsp;
                                    <template v-if="props.model.meta.paginator.total > 0">
                                        ( {{ props.model.from }} - {{ props.model.to }} of {{ props.model.meta.paginator.total }} )
                                    </template>
                                </th>
                            </template>

                            <template #bottom-display-from-to="props">
                                <div v-if="props.model.meta.paginator.total > 0" class="displayNo">
                                    {{ props.model.from }} - {{ props.model.to }} of
                                    {{ props.model.meta.paginator.total }}
                                </div>
                            </template>

                            <template #cdata="props">
                                <td>
                                    {{ props.model.displayName }}
                                    <template v-if="props.model.hasTeacherAccount">
                                        <span style="color: #717171"> (Also a teacher) </span>
                                    </template>
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    {{ props.model.email ? props.model.email : '-' }}
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    {{ props.model.organisationID ? props.model.organisationID : '' }}
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    <i
                                        class="fa fa-circle marginRight8"
                                        :class="{
                                            suspendCircle: ['Lapsed', 'Expired', 'Suspended'].includes(props.model.status),
                                            readOnlyCircle: ['Invite Sent', 'Not Yet Invited'].includes(props.model.status),
                                            activeCircle: ['Active', 'Active (Not Yet Paid)', 'Active (Paid)'].includes(props.model.status),
                                        }"
                                        aria-hidden="true"
                                    />
                                    {{ props.model.status }}
                                    <!-- <template v-if="props.model.isSuspended">
                    <i class="fa fa-circle suspendCircle"></i>&nbsp;Suspended
                  </template>
                  <template v-else>
                    <i class="fa fa-circle activeCircle"></i>&nbsp;Active
                  </template> -->
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    {{ props.model.origin }}
                                </td>

                                <td>
                                    <div class="dropdown">
                                        <button
                                            id="viewDropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            aria-label="Actions"
                                        >
                                            Actions<i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                            <ul>
                                                <li>
                                                    <router-link
                                                        v-tooltip="'View Student'"
                                                        class="primary-state"
                                                        :to="{
                                                            name: 'accounts.students.view',
                                                            params: {
                                                                id: props.model.uuid,
                                                            },
                                                        }"
                                                        aria-label="View Student"
                                                    >
                                                        <span> <i class="fas fa-eye marginRight8" aria-hidden="true" />View Student </span>
                                                    </router-link>
                                                </li>

                                                <li v-if="[1, 2, 3].includes(auth.user().roleId) && props.model.email">
                                                    <a
                                                        v-tooltip="'Reset Password'"
                                                        class="primary-state"
                                                        href="#"
                                                        aria-label="Reset Password"
                                                        @click.prevent="openResetPasswordModal(props.model)"
                                                    >
                                                        <span> <i class="fa fa-key marginRight8" aria-hidden="true" />Reset Password </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <!-- <div class="buttonGroupInline hidden-xs hidden-sm">
                    <router-link
                      tag="button"
                      v-tooltip="{ content: 'View Student' }"
                      :to="{
                        name: 'accounts.students.view',
                        params: { id: props.model.uuid },
                      }"
                      class="btn btn-primary subBtn"
                      aria-label="View"
                    >
                      <i class="fa fa-eye"></i>&nbsp;View
                    </router-link>
                    <router-link
                      tag="button"
                      v-tooltip="{ content: 'View' }"
                      v-if="
                        (!props.model.isSuspended && auth.user().roleId == 2) ||
                          props.model.uuid == auth.user().uuid
                      "
                      :to="{
                        name: 'students.edit',
                        params: { id: props.model.uuid },
                      }"
                      class="btn btn-default subBtn"
                    >
                      <i class="fa fa-pencil-square-o"></i>&nbsp;Edit
                    </router-link>
                    <button
                      v-if="
                        [1, 2, 3].includes(auth.user().roleId) &&
                          props.model.email
                      "
                      class="btn btn-default subBtn"
                      @click.prevent="openResetPasswordModal(props.model)"
                      aria-label="Reset Password"
                    >
                      <i class="fa fa-key"></i>&nbsp;Reset Password
                    </button>
                  </div> -->
                                    <!-- <div class="buttonGroupInline hidden-md hidden-lg">
                    <router-link
                      tag="button"
                      v-tooltip="{ content: 'View Student' }"
                      v-if="!props.model.isSuspended"
                      :to="{
                        name: 'accounts.students.view',
                        params: { id: props.model.uuid },
                      }"
                      class="btn btn-primary mainBtn"
                      aria-label="View"
                    >
                      <i class="fa fa-eye"></i>
                    </router-link>
                    <router-link
                      tag="button"
                      v-tooltip="{ content: 'View' }"
                      v-if="
                        (!props.model.isSuspended && auth.user().roleId == 2) ||
                          props.model.uuid == auth.user().uuid
                      "
                      :to="{
                        name: 'students.edit',
                        params: { id: props.model.uuid },
                      }"
                      class="btn btn-default mainBtn"
                    >
                      <i class="fa fa-pencil-square-o"></i>
                    </router-link>
                    <button
                      v-if="
                        [1, 2, 3].includes(auth.user().roleId) &&
                          props.model.email
                      "
                      class="btn btn-default mainBtn"
                      @click.prevent="openResetPasswordModal(props.model)"
                      v-tooltip="{ content: 'Reset Password' }"
                      aria-label="Reset Password"
                    >
                      <i class="fa fa-key"></i>
                    </button>
                  </div> -->
                                </td>
                            </template>
                        </kr-search>
                    </template>
                </kr-panel>
            </div>

            <div id="codes" class="tab-pane fade in" :class="{ active: tab == 'code' }">
                <kr-panel :with-footer="false">
                    <template #title> ACCESS CODES </template>

                    <template #content>
                        <kr-search
                            name="codes_listing"
                            :url="{
                                url: '/accounts/' + account.uuid + '/access-codes',
                            }"
                            :columns="{
                                checkbox: 'display:|sortable:false|width:5%|slotted:true',
                                bookstoreName: 'display:BOOKSTORE|sortable:false|width:8%',
                                accessCode: 'display:ACCESS CODE|sortable:false|width:8%|mobile:hide',
                                planName: 'display:PAYMENT PLAN|sortable:false|width:8%|mobile:hide',
                                dateCreated: 'display:DATE CREATED|sortable:false|width:8%|mobile:hide',
                                validityPeriod: 'display:VALIDITY PERIOD (WEEKS)|sortable:false|width:8%|mobile:hide',
                                toBeActivatedBeforeDate: 'display:TO BE USED BEFORE|sortable:false|width:8%|mobile:hide',
                                validForRefundDate: 'display:VALID FOR REFUND BEFORE|sortable:false|width:8%|mobile:hide',
                                status: 'display:CURRENT STATUS|sortable:false|width:10%',
                                dateUsed: 'display:USED ON|sortable:false|width:9%|mobile:hide',
                                usedBy: 'display:USED BY|sortable:false|width:9%|mobile:hide',
                                expiryDate: 'display:ACCOUNT EXPIRY DATE|sortable:false|width:9%|mobile:hide',
                                deactivatedOn: 'display:INVALIDATED ON|sortable:false|width:9%|mobile:hide',
                                refundedOn: 'display:REFUNDED ON|sortable:false|width:9%|mobile:hide',
                            }"
                            :options="{
                                activated_field: true,
                                trash_field: false,
                                suspended_field: false,
                                action_column: false,
                                search_field: true,
                                query_fields: query_fields,
                            }"
                            @retrieve-data="getAccessCodeData"
                        >
                            <template #upper-right>
                                <div class="buttonGroup">
                                    <div class="hidden-xs hidden-sm">
                                        <a class="marginLeft20">
                                            <button class="btn btn-primary mainBtn" aria-label="Download Access Codes Report" @click="download">
                                                <i class="fa fa-download marginRight8" aria-hidden="true" />Access Codes Report
                                            </button>
                                        </a>

                                        <router-link
                                            id="top-add-codes-btn"
                                            :to="{
                                                name: 'accounts.codes.create',
                                                params: {
                                                    account_id: account.uuid,
                                                },
                                            }"
                                            tag="button"
                                            class="btn btn-primary mainBtn"
                                            aria-label="New Access Codes"
                                        >
                                            <i class="fa fa-plus marginRight8" aria-hidden="true" />New Access Codes
                                        </router-link>

                                        <!-- <router-link
                      id="top-back-log-btn"
                      :to="{ name: 'accounts.index' }"
                      tag="button"
                      class="btn btn-default mainBtn"
                      exact
                    >
                      <i class="fa fa-caret-left"></i>&nbsp;Back
                    </router-link> -->
                                    </div>
                                    <div class="hidden-md hidden-lg">
                                        <a class="marginLeft20">
                                            <button class="btn btn-primary mainBtn" aria-label="Download" @click="download">
                                                <i class="fa fa-download" aria-hidden="true" />
                                            </button>
                                        </a>

                                        <router-link
                                            id="top-mobile-add-teacher-btn"
                                            :to="{
                                                name: 'accounts.codes.create',
                                                params: {
                                                    account_id: account.uuid,
                                                },
                                            }"
                                            tag="button"
                                            class="btn btn-primary mainBtn"
                                            aria-label="New Access Codes"
                                        >
                                            <i class="fa fa-plus" aria-hidden="true" />
                                        </router-link>

                                        <!-- <router-link
                      id="top-mobile-back-log-btn"
                      :to="{ name: 'accounts.index' }"
                      tag="button"
                      class="btn btn-default mainBtn"
                      exact
                    >
                      <i class="fa fa-caret-left"></i>
                    </router-link> -->
                                    </div>
                                </div>
                            </template>

                            <!-- <template slot="top-display-from-to" slot-scope="props">
                <div class="displayNo">
                  {{ props.model.from }} - {{ props.model.to }} of
                  {{ props.model.meta.paginator.total }}
                </div>
              </template> -->

                            <template #table-title="props">
                                <th colspan="14">
                                    All Access Codes ( {{ props.model.from }} - {{ props.model.to }} of {{ props.model.meta.paginator.total }} )
                                    <div class="inlineFlex align-items marginLeft20">
                                        <div v-if="codeFlags.canRefund" class="btn btn-default subBtn" @click="openAccessCodeActionModal('refund')">
                                            <i class="fas fa-undo marginRight8" aria-hidden="true" />Refund
                                        </div>

                                        <div v-if="codeFlags.canSuspend" class="btn btn-danger subBtn" @click="openAccessCodeActionModal('suspend')">
                                            <i class="fas fa-ban marginRight8" aria-hidden="true" />Suspend
                                        </div>

                                        <div v-if="codeFlags.canDeactivate" class="btn btn-default subBtn" @click="openAccessCodeActionModal('deactivate')">
                                            <i class="fas fa-user-times marginRight8" aria-hidden="true" />Deactivate
                                        </div>

                                        <div v-if="codeFlags.canExtend" class="btn btn-default subBtn" @click="openAccessCodeActionModal('extend')">
                                            <i class="fas fa-clock marginRight8" aria-hidden="true" />Extend Validity
                                        </div>

                                        <div v-if="codeFlags.canReactivate" class="btn btn-danger subBtn" @click="openAccessCodeActionModal('reactivate')">
                                            <i class="fas fa-redo-alt marginRight8" aria-hidden="true" />Reactivate
                                        </div>
                                    </div>
                                </th>
                            </template>

                            <template #bottom-display-from-to="props">
                                <div class="displayNo">
                                    {{ props.model.from }} - {{ props.model.to }} of
                                    {{ props.model.meta.paginator.total }}
                                </div>
                            </template>

                            <template #checkbox>
                                <div class="checkboxField">
                                    <input v-model="toggleAccessCodes" type="checkbox" aria-label="Toggle Access Codes" @change="toggleCodes" />
                                </div>
                            </template>

                            <template #cdata="props">
                                <td>
                                    <div v-if="canSelectAccessCode(props.model)" class="checkboxField">
                                        <input v-model="codeData" type="checkbox" :value="props.model.uuid" />
                                    </div>
                                </td>

                                <td>
                                    {{ props.model.bookstoreName }}
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    {{ props.model.code }}
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    {{ props.model.planName }}
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    <template v-if="props.model.dateCreated">
                                        {{ convertToReadableDate(props.model.dateCreated, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.dateCreated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>
                                    <template v-else> - </template>
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    {{ props.model.validityPeriod }}
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    <template v-if="props.model.toBeActivatedBeforeDate">
                                        {{ convertToReadableDate(props.model.toBeActivatedBeforeDate, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.toBeActivatedBeforeDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>

                                    <template v-else> - </template>
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    <template v-if="props.model.validForRefundDate">
                                        {{ convertToReadableDate(props.model.validForRefundDate, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.validForRefundDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>

                                    <template v-else> - </template>
                                </td>

                                <td>
                                    <template v-if="props.model.status == 'unactivated'">
                                        <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Unactivated
                                    </template>

                                    <template v-else-if="props.model.status == 'activated'">
                                        <i class="fa fa-circle activeCircle marginRight8" aria-hidden="true" />Activated
                                    </template>

                                    <template v-else-if="props.model.status == 'suspended'">
                                        <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Suspended
                                    </template>

                                    <template v-else-if="props.model.status == 'deactivated'">
                                        <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Deactivated
                                    </template>

                                    <template v-else-if="props.model.status == 'refunded'">
                                        <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Refunded
                                    </template>

                                    <template v-else-if="props.model.status == 'expired'">
                                        <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Expired
                                    </template>

                                    <template v-else> - </template>
                                    <br />
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    <template v-if="props.model.dateUsed">
                                        {{ convertToReadableDate(props.model.dateUsed, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.dateUsed, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>

                                    <template v-else> - </template>
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    <template v-if="props.model.usedByUuid">
                                        <router-link
                                            v-tooltip="{
                                                content: 'View Student',
                                            }"
                                            tag="a"
                                            :to="{
                                                name: 'accounts.students.view',
                                                params: {
                                                    id: props.model.usedByUuid,
                                                },
                                            }"
                                            class="link"
                                            :aria-label="props.model.usedBy"
                                        >
                                            {{ props.model.usedBy }}
                                        </router-link>
                                    </template>

                                    <template v-else> - </template>
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    <template v-if="props.model.expiryDate">
                                        {{ convertToReadableDate(props.model.expiryDate, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.expiryDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>

                                    <template v-else> - </template>
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    <template v-if="props.model.deactivatedOn">
                                        {{ convertToReadableDate(props.model.deactivatedOn, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.deactivatedOn, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>

                                    <template v-else> - </template>
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    <template v-if="props.model.refundedOn">
                                        {{ convertToReadableDate(props.model.refundedOn, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.refundedOn, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>

                                    <template v-else> - </template>
                                </td>
                            </template>

                            <template #ndata="props">
                                <td colspan="5" style="text-align: center; color: #717171">No access codes</td>
                            </template>
                        </kr-search>
                    </template>
                </kr-panel>
            </div>

            <div v-if="false" id="log" class="tab-pane fade in" :class="{ active: tab == 'log' }">
                <kr-panel :with-footer="false">
                    <template #title> ACTIVITY LOG </template>

                    <template #content>
                        <kr-search
                            name="log_listing"
                            :url="{ url: '/accounts/activity-log/index' }"
                            :columns="{
                                user: 'display:USER|sortable:false|width:35%|mobile:hide',
                                event: 'display:ACTION|sortable:false|width:30%',
                                dateCreated: 'display:TIMESTAMP|sortable:false|width:45%',
                            }"
                            :options="{
                                trash_field: false,
                                suspended_field: false,
                                action_column: false,
                                search_field: false,
                                query_fields: query_fields,
                                params: { accountUuid: $route.params.id },
                            }"
                            @mounted="
                                initStartDatepicker();
                                initEndDatepicker();
                            "
                        >
                            <template #lower-left>
                                <div class="form-group periodBars">
                                    <label class="col-form-label">VIEW PERIOD</label>

                                    <div class="form-date">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="start_log_daterange"
                                            placeholder="Select start period"
                                            aria-label="Select Start Period"
                                        />
                                    </div>

                                    <div class="form-date">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="end_log_daterange"
                                            placeholder="Select end period"
                                            aria-label="Select End Period"
                                        />
                                    </div>
                                </div>
                            </template>

                            <template #upper-right>
                                <div class="hidden-xs hidden-sm">
                                    <router-link
                                        id="top-back-log-btn"
                                        :to="{ name: 'accounts.index' }"
                                        tag="button"
                                        class="btn btn-default mainBtn"
                                        exact
                                        aria-label="Back"
                                    >
                                        <i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back
                                    </router-link>
                                </div>

                                <div class="hidden-md hidden-lg marginLeft20">
                                    <router-link
                                        id="top-mobile-back-log-btn"
                                        :to="{ name: 'accounts.index' }"
                                        tag="button"
                                        class="btn btn-default mainBtn"
                                        exact
                                        aria-label="Back"
                                    >
                                        <i class="fa fa-caret-left" aria-hidden="true" />
                                    </router-link>
                                </div>
                            </template>

                            <!-- <template slot="top-display-from-to" slot-scope="props">
                <div class="displayNo">
                  {{ props.model.from }} - {{ props.model.to }} of
                  {{ props.model.meta.paginator.total }}
                </div>
              </template> -->

                            <template #table-title="props">
                                <th colspan="6">All Logs ( {{ props.model.from }} - {{ props.model.to }} of {{ props.model.meta.paginator.total }} )</th>
                            </template>

                            <template #bottom-display-from-to="props">
                                <div class="displayNo">
                                    {{ props.model.from }} - {{ props.model.to }} of
                                    {{ props.model.meta.paginator.total }}
                                </div>
                            </template>

                            <template #cdata="props">
                                <td class="hidden-xs hidden-sm">
                                    {{ props.model.user }}
                                </td>

                                <td>
                                    {{ props.model.event }}
                                </td>

                                <td>
                                    <template v-if="props.model.dateCreated">
                                        {{ convertToReadableDate(props.model.dateCreated, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.dateCreated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>

                                    <template v-else> - </template>
                                </td>
                            </template>
                        </kr-search>
                    </template>
                </kr-panel>
            </div>

            <div id="payment-log" class="tab-pane fade in" :class="{ active: tab == 'payment-log' }">
                <kr-panel :with-footer="false">
                    <template #title> PAYMENT LOG </template>
                    <template #content>
                        <kr-search
                            name="payment_log_listing"
                            :url="{
                                url: `/accounts/${$route.params.id}/payment-logs`,
                            }"
                            :columns="{
                                type: 'display:TYPE|sortable:false|width:35%|mobile:hide',
                                timestamp: 'display:TIMESTAMP|sortable:false',
                                id: 'display:ID|sortable:false',
                                name: 'display:NAME|sortable:false',
                                email: 'display:EMAIL|sortable:false',
                                status: 'display:STATUS|sortable:false',
                                details: 'display:DETAILS|sortable:false',
                            }"
                            :options="{
                                trash_field: false,
                                suspended_field: false,
                                action_column: false,
                                search_field: true,
                                query_fields: query_fields,
                                params: { accountUuid: $route.params.id },
                            }"
                            @mounted="
                                initStartDatepicker();
                                initEndDatepicker();
                            "
                        >
                            <template #table-title="props">
                                <th colspan="7">All Logs ( {{ props.model.from }} - {{ props.model.to }} of {{ props.model.meta.paginator.total }} )</th>
                            </template>
                            <template #bottom-display-from-to="props">
                                <div class="displayNo">
                                    {{ props.model.from }} - {{ props.model.to }} of
                                    {{ props.model.meta.paginator.total }}
                                </div>
                            </template>
                            <template #cdata="props">
                                <td>
                                    {{ props.model.paymentMode }}
                                    <!-- <template v-if="props.model.paymentMode == 'paypal'">
                    Payment
                  </template>
                  <template v-else>
                    Access Code
                  </template> -->
                                </td>

                                <td>
                                    <template v-if="props.model.dateCreated">
                                        {{ convertToReadableDate(props.model.dateCreated, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.dateCreated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>

                                    <template v-else> - </template>
                                </td>

                                <td>
                                    {{ props.model.id }}
                                </td>

                                <td>
                                    {{ props.model.fullname }}
                                </td>

                                <td>
                                    {{ props.model.email }}
                                </td>

                                <td>
                                    <span :class="props.model.status">
                                        {{ props.model.status }}
                                    </span>
                                </td>

                                <td>
                                    <button
                                        v-if="props.model.paymentMethod != 'paypal'"
                                        class="btn btn-primary subBtn"
                                        aria-label="Details"
                                        @click="openDetailsModal(props.model.details, props.model.status)"
                                    >
                                        Details
                                    </button>
                                    <span v-else :class="props.model.status">{{ props.model.details }}</span>
                                </td>
                            </template>
                        </kr-search>
                    </template>
                </kr-panel>
            </div>
        </div>

        <!-- <div class="row">
      <div class="col-md-6 col-xs-12">
        <kr-panel :withFooter="false">
          <template #title>
            <div class="x_title">
              ACCOUNT OVERVIEW
              <span style="float:right">
                <router-link
                  :to="{ name: 'accounts.edit', params: { id: account.uuid } }"
                  ><i class="fa fa-pencil-square-o"></i> Edit</router-link
                >
              </span>
            </div>
          </template>
          <template #content>
            <div class="row">
              <div class="col-xs-6" style="margin-top:10px;">
                <div class="form-group">
                  <label class="control-label text-uppercase"
                    >ORGANISATION/INSTITUTION</label
                  >
                  <p class="form-control-static">
                    {{ account.organisationName }}
                  </p>
                </div>
              </div>
              <div class="col-xs-6" style="margin-top:10px;">
                <div class="form-group">
                  <label class="control-label text-uppercase"
                    >ORGANISATION SUB-DOMAIN/ACCOUNT NAME</label
                  >
                  <p class="form-control-static">{{ account.accountName }}</p>
                </div>
              </div>
              <div class="col-xs-6">
                <div class="form-group">
                  <label class="control-label text-uppercase">TYPE</label>
                  <p class="form-control-static">{{ account.type }}</p>
                </div>
              </div>
              <div class="col-xs-6">
                <div class="form-group">
                  <label class="control-label text-uppercase"
                    >PAYMENT METHOD</label
                  >
                  <p class="form-control-static">{{ account.paymentMethod }}</p>
                </div>
              </div>
            </div>
          </template>
        </kr-panel>
      </div>
      <div class="col-md-6 col-xs-12"></div>
    </div> -->

        <!-- Modal -->
        <div id="suspendModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="suspendModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="suspendModal-title" class="modal-title">
                            <template v-if="!account.isSuspended"> Suspend </template>
                            <template v-else> Reinstate </template>
                            {{ account.organisationName }}
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div v-if="!account.isSuspended" class="col-xs-12" style="text-align: center">
                                <div>
                                    You are about to suspend
                                    {{ account.organisationName }}
                                </div>
                                <div>Are you sure ?</div>
                            </div>
                            <div v-else class="col-xs-12" style="text-align: center">
                                <div>
                                    You are about to reinstate
                                    {{ account.organisationName }}
                                </div>
                                <div>Are you sure ?</div>
                            </div>
                        </div>
                        <div class="row">
                            <!--<div v-if="suspendObject.status=='success'" class="alert alert-success" role="alert" style="margin-bottom:10px">
	                            <i class="fa fa-check"></i>&nbsp; You have successfully performed action
	                        </div>
	                        <div  v-if="suspendObject.status=='fail'" class="alert alert-danger" role="alert" style="margin-bottom:10px">
	                            <i class="fa fa-cross"></i>&nbsp; Error in your action please try again!
	                        </div>-->
                            <div class="flex">
                                <div v-if="!account.isSuspended" class="col-xs-12 col-md-6">
                                    <button
                                        id="modal-suspend-btn"
                                        class="btn btn-danger mainBtn"
                                        style="width: 100%"
                                        aria-label="Suspend"
                                        @click.prevent="suspend()"
                                    >
                                        <i class="fa fa-archive marginRight8" aria-hidden="true" />Suspend
                                    </button>
                                </div>
                                <div v-else class="col-xs-12 col-md-6">
                                    <button
                                        id="modal-reinstate-btn"
                                        class="btn btn-success mainBtn"
                                        style="width: 100%"
                                        aria-label="Reinstate"
                                        @click.prevent="unsuspend()"
                                    >
                                        <i class="fa fa-refresh marginRight8" aria-hidden="true" />Reinstate
                                    </button>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <button id="modal-dismiss-btn" class="btn btn-default mainBtn" style="width: 100%" data-dismiss="modal" aria-label="Cancel">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!--<div class="row" v-else>
	                        <div class="col-xs-12" style="text-align:center">
	                           <template v-if="!superuser.isSuspended">
	                           		Suspending..
	                           </template>
	                           <template v-else>
	                           		Reinstating
	                           </template>
	                        </div>
	                    </div>-->
                    </div>
                </div>
            </div>
        </div>

        <!--- Refund --->
        <div id="refund-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="refundModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="refundModal-title" class="modal-title">Refund</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 marginBottom15 textAlignCenter">
                            <div class="fontBold">You are about to refund the following codes</div>
                        </div>
                        <div class="col-xs-12 textAlignCenter">
                            <ul class="modal-points">
                                <template v-for="(code, idx) in cDataFiltered">
                                    <li>
                                        <div>
                                            <i class="fas fa-circle" aria-hidden="true" />
                                            <div>
                                                {{ code.code }} ({{ code.validityPeriod }}
                                                months)
                                            </div>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </div>
                        <div class="col-xs-12 col-md-6 col-md-offset-3 textAlignCenter">
                            <div>Are you sure?</div>
                        </div>

                        <div class="flex marginTop30">
                            <button class="btn btn-success mainBtn" data-dismiss="modal" aria-label="Confirm" @click="refundCodes">
                                <i class="fas fa-check marginRight8" aria-hidden="true" />Confirm
                            </button>
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--- Suspend --->
        <div id="suspend-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="suspendModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="suspendModal-title" class="modal-title">Suspend</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 marginBottom15 textAlignCenter">
                            <div class="fontBold">You are about to suspend the following codes</div>
                        </div>
                        <div class="col-xs-12 textAlignCenter">
                            <ul class="modal-points">
                                <template v-for="(code, idx) in cDataFiltered">
                                    <li>
                                        <div>
                                            <i class="fas fa-circle" aria-hidden="true" />
                                            <div>
                                                {{ code.code }} ({{ code.validityPeriod }}
                                                months)
                                            </div>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </div>
                        <div class="col-xs-12 col-md-6 col-md-offset-3 textAlignCenter">
                            <div>Are you sure?</div>
                        </div>

                        <div class="flex marginTop30">
                            <button class="btn btn-success mainBtn" data-dismiss="modal" aria-label="Confirm" @click="suspendCodes">
                                <i class="fas fa-check marginRight8" aria-hidden="true" />Confirm
                            </button>
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--- Deactivate --->
        <div id="deactivate-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="deactivateModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="deactivateModal-title" class="modal-title">Deactivate</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 marginBottom15 textAlignCenter">
                            <div class="fontBold">You are about to deactivate the following codes</div>
                        </div>
                        <div class="col-xs-12 textAlignCenter">
                            <ul class="modal-points">
                                <template v-for="(code, idx) in cDataFiltered">
                                    <li>
                                        <div>
                                            <i class="fas fa-circle" aria-hidden="true" />
                                            <div>
                                                {{ code.code }} ({{ code.validityPeriod }}
                                                months)
                                            </div>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </div>
                        <div class="col-xs-12 col-md-6 col-md-offset-3 textAlignCenter">
                            <div>Are you sure?</div>
                        </div>

                        <div class="flex marginTop30">
                            <button class="btn btn-success mainBtn" data-dismiss="modal" aria-label="Confirm" @click="deactivateCodes">
                                <i class="fas fa-check marginRight8" aria-hidden="true" />Confirm
                            </button>
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--- Reactivate --->
        <div id="reactivate-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="deactivateModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="deactivateModal-title" class="modal-title">Reactivate</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 marginBottom15 textAlignCenter">
                            <div class="fontBold">You are about to reactivate the following codes</div>
                        </div>
                        <div class="col-xs-12 textAlignCenter">
                            <ul class="modal-points">
                                <template v-for="(code, idx) in cDataFiltered">
                                    <li>
                                        <div>
                                            <i class="fas fa-circle" aria-hidden="true" />
                                            <div>
                                                {{ code.code }} ({{ code.validityPeriod }}
                                                months)
                                            </div>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </div>
                        <div class="col-xs-12 col-md-6 col-md-offset-3 textAlignCenter">
                            <div>Are you sure?</div>
                        </div>

                        <div class="flex marginTop30">
                            <button class="btn btn-success mainBtn" data-dismiss="modal" aria-label="Confirm" @click="reactivateCodes">
                                <i class="fas fa-check marginRight8" aria-hidden="true" />Confirm
                            </button>
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--- Extend Validity --->
        <div id="extend-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="extendModal-title">
            <div class="modal-dialog primary-modal">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h1 id="extendModal-title" class="modal-title">Extend Validity</h1>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <h2>Extending the validity</h2>

                        <div class="col-xs-12 col-md-8 col-md-offset-2 marginTop20">
                            <ul class="textAlignLeft">
                                <h3 class="control-label">List of {{ cDataFiltered.length }} Codes:</h3>
                                <template v-for="(code, idx) in cDataFiltered">
                                    <li class="fs-18px"><b>Code:</b> {{ code.code }} ({{ code.validityPeriod }} Months)</li>
                                </template>
                            </ul>
                        </div>

                        <p class="col-xs-12 col-md-6 col-md-offset-3">How many more months would you like to extend the validity of these access codes by?</p>

                        <div class="textAlignCenter marginTop20">
                            <label for="extendMonths-input" class="control-label">Set Extend Validity</label>
                            <div class="flex justifyCenter">
                                <div class="position-relative width68px height45px">
                                    <input
                                        id="extendMonths-input"
                                        v-model="extendMonths"
                                        type="number"
                                        class="form-control"
                                        aria-labelledby="extendMonths-description"
                                    />
                                    <span class="quantity-nav">
                                        <div class="quantity-button quantity-up" @click="extendMonths++">
                                            <i class="fas fa-plus" aria-hidden="true" />
                                        </div>
                                        <div class="quantity-button quantity-down" @click="extendMonths > 0 ? extendMonths-- : ''">
                                            <i class="fas fa-minus" aria-hidden="true" />
                                        </div>
                                    </span>
                                </div>
                                <div id="extendMonths-description" class="input-group-addon fs-14px widthAuto flex align-items">Months</div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">No, Cancel</button>
                        <button class="btn btn-success" data-dismiss="modal" @click="extendCodes">Yes, Confirm</button>
                    </div>
                </div>
            </div>
        </div>

        <div id="details-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="detailsModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="detailsModal-title" class="modal-title">Details</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 marginBottom15">
                            <pre v-html="payment_detail" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--- Suspend --->
        <div id="delete-note-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="deleteNoteModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="deleteNoteModal-title" class="modal-title">Delete Note</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 marginBottom15 textAlignCenter">
                            <div class="fontBold">You are about to delete this note</div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-md-offset-3 textAlignCenter">
                            <div>Are you sure?</div>
                        </div>

                        <div class="flex marginTop30">
                            <button class="btn btn-danger mainBtn" data-dismiss="modal" aria-label="Confirm" @click="deleteNote">
                                <i class="fas fa-check marginRight8" aria-hidden="true" />Confirm
                            </button>
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <kr-reset-password />

        <div id="impersonationModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="impersonationModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="impersonationModal-title" class="modal-title">Impersonation Confirmation</h2>
                    </div>

                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xs-12 textAlignCenter">
                                <div>
                                    Are you sure you want to impersonate
                                    {{ user.displayName }}?
                                </div>
                            </div>
                        </div>
                        <div class="row marginTop10">
                            <div class="flex">
                                <button class="btn btn-success mainBtn" aria-label="Impersonate" @click.prevent="impersonate()">Impersonate</button>
                                <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="remove-superadmin-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="remove-superadmin-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="remove-superadmin-title" class="modal-title">Remove Super Admin role</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 marginBottom15 textAlignCenter">
                            <div v-if="teacher" class="fontBold">
                                You are about to remove Super Admin role for
                                {{ teacher.displayName }}
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-md-offset-3 textAlignCenter">
                            <div>Are you sure?</div>
                        </div>

                        <div class="flex marginTop30">
                            <button class="btn btn-success mainBtn" data-dismiss="modal" aria-label="Confirm" @click="removeSuperAdmin">
                                <i class="fas fa-check marginRight8" aria-hidden="true" />Confirm
                            </button>
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrForm from '../../../components/forms/form';
import KrAuth from '../../../components/auth/auth';
import { useAuthUserStore } from '../../../stores/auth';
export default {
    data() {
        var auth = new KrAuth();
        var store = useAuthUserStore();
        let history = store.search_history;
        let search_history_id = 'accounts_students_index';
        return {
            component_done_loading: false,
            auth: auth,
            account: null,
            query_fields: { dateFrom: '', dateTo: '' },
            teacherSearchData: {
                meta: { paginator: { total: 0 }, filters: {} },
                query: { isSuspended: '0' },
            },
            tab: 'account',
            isOpened: false,
            payment_detail: '',
            accessCodes: [],
            codeData: [],
            cDataFiltered: [],
            codeFlags: {
                canRefund: false,
                canSuspend: false,
                canReactivate: false,
                canDeactivate: false,
                canExtend: false,
            },
            extendMonths: 3,
            toggleAccessCodes: false,
            notes: [],
            note: {}, //note to delete
            user: {},
            studentSearchData: {
                meta: { paginator: { total: 0 }, filters: {} },
                query: {},
            },
            coursesList: [],
            paidSettings: false,
            course: 'all',
            student_query_fields: {
                status: _.get(history, `${search_history_id}.status`, ''),
                origin: _.get(history, `${search_history_id}.origin`, ''),
                courseUuid: _.get(history, `${search_history_id}.courseUuid`, ''),
                enrolled: _.get(history, `${search_history_id}.enrolled`, ''),
                accountUuid: this.$route.params.id,
            },
            resultQuery: [],
            perPage: 10,
            pageNumber: 0,
            teacher: null,
            superAdminSearchData: {
                meta: { paginator: { total: 0 }, filters: {} },
                query: { isSuspended: '0' },
            },
        };
    },
    watch: {
        codeData: {
            handler: function () {
                if (this.codeData.length == 0) {
                    this.toggleAccessCodes = false;
                }
                if (this.accessCodes.length === this.codeData.length) {
                    this.toggleAccessCodes = true;
                }
                this.processFlags();
            },
            deep: true,
        },
    },
    created() {
        this.tab = _.get(this, '$route.query.tab', 'account');
        Events.fire('topbar_update', {
            title: '<i class="fas fa-id-card"></i>&nbsp;Accounts',
            breadcrumb: [{ name: 'Home' }, { name: 'Accounts', link: 'accounts.index' }],
        });
        this.fetch();
        this.fetchNotes();
    },
    methods: {
        searchCourseDropdown() {
            this.resultQuery = [];
            this.pageNumber = 0;

            if (this.studentSearchData.q.match(/[a-z]/i) != null) {
                this.studentSearchData.isSearching = true;
                this.studentSearchData.matched = 0;
                for (var i = 0; i < this.coursesList.length; i++) {
                    //Vue.set(this.coursesList[i], '_matched', 0);
                    this.coursesList[i]._matched = 0;
                    let course = this.coursesList[i];
                    let haystack = (course.name + course.code + '').toLowerCase();
                    let doesContain = haystack.indexOf(this.studentSearchData.q.toLowerCase());
                    if (doesContain != -1) {
                        couse._searchDisplay = true;
                        //Vue.set(course, '_searchDisplay', true);
                        this.studentSearchData.matched++;
                        this.coursesList[i]._matched++;

                        this.resultQuery.push(this.coursesList[i]);
                    } else {
                        //Vue.set(course, '_searchDisplay', false);
                        couse._searchDisplay = false;
                    }
                }
            } else {
                this.searchData.isSearching = false;
            }
        },
        select(option) {
            var that = this;
            this.course = false;
            this.student_query_fields.courseUuid = '';
            if (option === 'all') {
                this.course = 'all';
                this.student_query_fields.enrolled = '';
            } else if (option === 'enrolled') {
                this.course = 'enrolled';
                this.student_query_fields.enrolled = 1;
            } else if (option === 'not enrolled') {
                this.course = 'not enrolled';
                this.student_query_fields.enrolled = 0;
            } else {
                this.student_query_fields.courseUuid = option.uuid;
                if (this.student_query_fields.courseUuid != '') {
                    this.course = _.find(this.coursesList, function (o) {
                        return o.uuid == that.student_query_fields.courseUuid;
                    });
                }
            }
        },
        next(event) {
            this.pageNumber++;
            event.stopPropagation();
        },
        previous(event) {
            this.pageNumber--;
            event.stopPropagation();
        },
        courseHighlight(text) {
            if (this.studentSearchData.q != '') {
                if (text != null) {
                    return text.replace(new RegExp(this.studentSearchData.q, 'gi'), '<span class="table-keyword-highlight">$&</span>');
                }
            } else {
                return text;
            }
        },
        syntaxHighlight(json, status) {
            if (typeof json != 'string') {
                json = JSON.stringify(json, undefined, 2);
            }
            json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
            return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
                var cls = status;
                return '<span class="' + cls + '">' + match + '</span>';
            });
        },
        getInfluencerLevelLabel(influencerLevel) {
            let idx = _.findIndex(this.kr_var.influencerLevels, function (o) {
                return o.value == influencerLevel;
            });
            if (idx != -1) {
                return this.kr_var.influencerLevels[idx].description;
            }
            return '';
        },
        toggleCodes() {
            this.codeData = [];
            if (this.toggleAccessCodes) {
                for (var i = 0; i < this.accessCodes.length; i++) {
                    let obj = this.accessCodes[i];
                    if (this.canSelectAccessCode(obj)) {
                        this.codeData.push(obj.uuid);
                    }
                }
            }
        },
        openAccessCodeActionModal(type) {
            if (type == 'extend') {
                this.extendMonths = 3;
            }
            this.cDataFiltered = [];
            for (var i = 0; i < this.codeData.length; i++) {
                let obj = this.getAccessCodeObject(this.codeData[i]);
                if (['unactivated', 'suspended'].includes(obj.status)) {
                    if (type == 'refund' || type == 'deactivate' || type == 'extend') {
                        this.cDataFiltered.push(obj);
                    }
                }
                if (['unactivated'].includes(obj.status)) {
                    if (type == 'suspend') {
                        this.cDataFiltered.push(obj);
                    }
                }
                if (['suspended'].includes(obj.status)) {
                    if (type == 'reactivate') {
                        this.cDataFiltered.push(obj);
                    }
                }
                if (['expired'].includes(obj.status)) {
                    if (type == 'extend') {
                        this.cDataFiltered.push(obj);
                    }
                }
            }
            $('#' + type + '-modal').modal('show');
        },
        refundCodes() {
            var that = this;
            axios
                .put(`/accounts/${this.$route.params.id}/access-codes/bulk/refund`, {
                    data: this.cDataFiltered,
                })
                .then(function (response) {
                    that.codeData = [];
                    that.codeData.splice();
                    that.processFlags();
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Access Codes have been refunded',
                    });
                    Events.fire('codes_listing_refresh');
                })
                .catch(function () {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later ',
                    });
                });
        },
        suspendCodes() {
            var that = this;
            axios
                .put(`/accounts/${this.$route.params.id}/access-codes/bulk/suspend`, {
                    data: this.cDataFiltered,
                })
                .then(function (response) {
                    that.codeData = [];
                    that.codeData.splice();
                    that.processFlags();
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Access Codes have been suspended',
                    });
                    Events.fire('codes_listing_refresh');
                })
                .catch(function () {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later ',
                    });
                });
        },
        deactivateCodes() {
            var that = this;
            axios
                .put(`/accounts/${this.$route.params.id}/access-codes/bulk/deactivate`, { data: this.cDataFiltered })
                .then(function (response) {
                    that.codeData = [];
                    that.codeData.splice();
                    that.processFlags();
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Access Codes have been deactivated',
                    });
                    Events.fire('codes_listing_refresh');
                })
                .catch(function () {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later ',
                    });
                });
        },
        reactivateCodes() {
            var that = this;
            axios
                .put(`/accounts/${this.$route.params.id}/access-codes/bulk/reactivate`, { data: this.cDataFiltered })
                .then(function (response) {
                    that.codeData = [];
                    that.codeData.splice();
                    that.processFlags();
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Access Codes have been reactivated',
                    });
                    Events.fire('codes_listing_refresh');
                })
                .catch(function () {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later ',
                    });
                });
        },
        extendCodes() {
            var that = this;
            axios
                .put(`/accounts/${this.$route.params.id}/access-codes/bulk/update-period`, { months: this.extendMonths, data: this.cDataFiltered })
                .then(function (response) {
                    that.codeData = [];
                    that.codeData.splice();
                    that.processFlags();
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Access Codes validity have been extended',
                    });
                    Events.fire('codes_listing_refresh');
                })
                .catch(function () {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later ',
                    });
                });
        },
        getAccessCodeData(ret) {
            this.accessCodes = ret.data.data.data;
        },
        getAccessCodeObject(uuid) {
            let idx = _.findIndex(this.accessCodes, function (o) {
                return o.uuid == uuid;
            });
            if (idx != -1) {
                return this.accessCodes[idx];
            } else {
                return {};
            }
        },
        processFlags() {
            let refund = false;
            let suspend = false;
            let reactivate = false;
            let deactivate = false;
            let extend = false;

            for (var i = 0; i < this.codeData.length; i++) {
                let obj = this.getAccessCodeObject(this.codeData[i]);
                if (['unactivated', 'suspended'].includes(obj.status)) {
                    refund = true;
                    deactivate = true;
                    extend = true;
                }
                if (['unactivated'].includes(obj.status)) {
                    suspend = true;
                }
                if (['suspended'].includes(obj.status)) {
                    reactivate = true;
                }
                if (['expired'].includes(obj.status)) {
                    extend = true;
                }
            }
            this.codeFlags.canRefund = refund;
            this.codeFlags.canSuspend = suspend;
            this.codeFlags.canReactivate = reactivate;
            this.codeFlags.canDeactivate = deactivate;
            this.codeFlags.canExtend = extend;
        },
        canSelectAccessCode(code) {
            if (['activated', 'deactivated', 'refunded'].includes(code.status)) {
                return false;
            }
            return true;
        },
        openDetailsModal(details, status) {
            this.payment_detail = this.syntaxHighlight(details, status);
            $('#details-modal').modal('show');
        },
        download() {
            var that = this;
            axios({
                url: `/accounts/${this.$route.params.id}/access-codes/export`,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${this.account.organisationName}_ACCESS_CODES_REPORT_` + moment().format('YYYY_MM_DD') + '.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        openImpersonationModal(user) {
            this.user = user;
            $('#impersonationModal').modal('show');
        },
        impersonate() {
            var that = this;
            let userUuid = this.user.uuid;
            axios
                .post('auth/impersonate', { userUuid: userUuid })
                .then(function (response) {
                    let token = response.data.data.token;
                    axios
                        .post('/auth/validate?impersonate', {}, { headers: { Authorization: `Bearer ${token}` } })
                        .then(function (response) {
                            that.auth.login(response.data, {
                                impersonate: true,
                            });
                        })
                        .catch(function (errors) {
                            that.$notify({
                                group: 'form',
                                type: 'error',
                                title: 'Error',
                                text: 'Something went wrong, please try again later ',
                            });
                        });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later ',
                    });
                });
        },
        getSuperAdminSearchData(data) {
            this.superAdminSearchData = data;
        },
        getTeacherSearchData(data) {
            this.teacherSearchData = data;
        },
        openSuspendModal() {
            $('#suspendModal').modal('show');
        },
        suspend() {
            $('#suspendModal').modal('hide');
            var success = function () {
                this.account.isSuspended = true;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Account has been suspended',
                });
            }.bind(this);
            var del = function () {
                this.account.isSuspended = false;
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .put('/accounts/' + this.account.uuid + '/suspend')
                .then(function (response) {
                    success();
                })
                .catch(function (errors) {
                    del();
                });
        },
        unsuspend() {
            $('#suspendModal').modal('hide');
            var success = function () {
                var that = this;
                this.account.isSuspended = false;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Account has been reinstated',
                });
            }.bind(this);
            var del = function () {
                this.account.isSuspended = true;
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .put('/accounts/' + this.account.uuid + '/suspend')
                .then(function (response) {
                    success();
                })
                .catch(function (errors) {
                    del();
                });
        },
        search() {
            Events.fire('log_listing_refresh');
        },
        initStartDatepicker() {
            var that = this;
            let options = {
                maxDate: that.query_fields.dateTo,
                singleDatePicker: true,
                timePicker: false,
                cancelClass: '',
                showDropdowns: true,
                autoUpdateInput: false,
                locale: {
                    format: 'YYYY-MM-DD',
                },
            };
            if (that.query_fields.dateFrom != '') {
                options.startDate = moment.utc(that.query_fields.dateFrom).format('YYYY-MM-DD');
            }
            $('input[name="start_log_daterange"]').daterangepicker(options);
            $('input[name="start_log_daterange"]').on('apply.daterangepicker', function (ev, picker) {
                _.set(that.query_fields, 'dateFrom', picker.startDate.format('YYYY-MM-DD'));
                $('input[name="start_log_daterange"]').val(picker.startDate.format('LL'));
                that.search();
                Vue.nextTick(function () {
                    that.initEndDatepicker();
                });
            });
            $('input[name="start_log_daterange"]').on('cancel.daterangepicker', function (ev, picker) {
                $(this).val('');
                _.set(that.query_fields, 'dateFrom', '');
                that.search();
                Vue.nextTick(function () {
                    that.initEndDatepicker();
                });
            });
        },
        initEndDatepicker() {
            var that = this;
            let options = {
                minDate: that.query_fields.dateFrom,
                singleDatePicker: true,
                timePicker: false,
                cancelClass: '',
                showDropdowns: true,
                autoUpdateInput: false,
                locale: {
                    format: 'YYYY-MM-DD',
                },
            };
            if (that.query_fields.dateTo != '') {
                options.startDate = moment.utc(that.query_fields.dateTo).format('YYYY-MM-DD');
            }
            $('input[name="end_log_daterange"]').daterangepicker(options);
            $('input[name="end_log_daterange"]').on('apply.daterangepicker', function (ev, picker) {
                _.set(that.query_fields, 'dateTo', picker.startDate.format('YYYY-MM-DD'));
                $('input[name="end_log_daterange"]').val(picker.startDate.format('LL'));
                that.search();
                Vue.nextTick(function () {
                    that.initStartDatepicker();
                });
            });
            $('input[name="end_log_daterange"]').on('cancel.daterangepicker', function (ev, picker) {
                $(this).val('');
                _.set(that.query_fields, 'dateTo', '');
                that.search();
                Vue.nextTick(function () {
                    that.initStartDatepicker();
                });
            });
        },
        openResetPasswordModal(user, module) {
            if (module == 'account') {
                Events.fire('open-reset-password-modal', {
                    user: user.adminTeacherAccount,
                    organisation: this.account.organisationName,
                });
            } else {
                Events.fire('open-reset-password-modal', {
                    user: user,
                    organisation: this.account.organisationName,
                });
            }
        },
        fetchNotes() {
            let that = this;
            axios.get('/accounts/' + this.$route.params.id + '/notes').then(function (response) {
                that.notes = response.data.data;
            });
        },
        fetch() {
            let that = this;
            axios.get('/accounts/' + this.$route.params.id).then(function (response) {
                that.account = response.data.data;
                that.coursesList = that.account.courses;
                if (['Paid', 'Paid-Trial'].includes(_.get(that.account, 'type'))) {
                    if (['Institution'].includes(_.get(that.account, 'paymentMethod'))) {
                        that.paidSettings = 2;
                    } else {
                        that.paidSettings = 3;
                    }
                } else {
                    that.paidSettings = false;
                }
                Events.fire('topbar_update', {
                    breadcrumb: [{ name: 'Home' }, { name: 'Accounts', link: 'accounts.index' }, { name: that.account.organisationName }],
                });
                that.component_done_loading = true;
            });
        },
        toggle(event) {
            this.isOpened = !this.isOpened;
        },

        openDeleteNoteModal(note) {
            this.note = note;
            $('#delete-note-modal').modal('show');
        },
        deleteNote() {
            var that = this;
            axios
                .delete(`/accounts/${this.$route.params.id}/notes/${this.note.uuid}`)
                .then(function (response) {
                    that.fetchNotes();
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Note has been deleted',
                    });
                })
                .catch(function () {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later ',
                    });
                });
        },
        getStudentSearchData(data) {
            this.studentSearchData = data;
        },
        downloadStudents() {
            var that = this;
            let d = moment().format('YYYY[_]MM[_]DD');
            axios({
                url: `students?filter[q]=${this.studentSearchData.query.q}&accountUuid=${this.$route.params.id}&filter[origin]=${this.studentSearchData.query.origin}&filter[status]=${this.studentSearchData.query.status}&filter[courseUuid]=${this.studentSearchData.query.courseUuid}&filter[enrolled]=${this.studentSearchData.query.enrolled}&export=true`,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Students_' + d + '.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        courseChange() {
            var that = this;
            this.course = false;
            if (this.student_query_fields.courseUuid != '') {
                this.course = _.find(this.coursesList, function (o) {
                    return o.uuid == that.student_query_fields.courseUuid;
                });
            }
        },
        openRemoveSuperAdminConfirmationModal(teacher) {
            this.teacher = teacher;
            $('#remove-superadmin-modal').modal('show');
        },
        removeSuperAdmin() {
            var that = this;
            axios
                .delete(`/accounts/${this.$route.params.id}/superadmin`, {
                    data: { superadminUuid: this.teacher.uuid },
                })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Super Admin role has been removed',
                    });
                    Events.fire('superadmins_listing_refresh');
                })
                .catch(function () {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later ',
                    });
                });
        },
    },
};
</script>

<style scoped>
.noteDiv {
    margin-bottom: 30px;
}
.noteDiv .alert {
    min-height: 50px;
}
.noteDiv .actions {
    margin-top: 10px;
}
.failed {
    color: red;
}
.success {
    color: #3e7c5b;
}
.drop-down {
    width: 172px;
    height: 36px;
    border-radius: 3px;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    border: 1px solid #d8d8d8;
}

/* .dropdown .dropdown-menu {
    top: 10%;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    margin-bottom:0;
} */

#viewDropdownMenuButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.viewDropdown {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

@media (min-width: 992px) {
    .periodBars {
        width: 524px;
    }
}

.quantity-nav {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
    color: #222;
    z-index: 4;
    height: 100%;
    max-height: 100%;
}

.quantity-nav .disabled {
    color: #dfe3e9;
    cursor: not-allowed;
}

.quantity-button {
    min-height: 18px;
    height: 50%;
    display: flex;
    align-items: center;
    width: 22px;
    justify-content: center;
    background-color: white !important;
    /* border: 1px solid #dfe3e9 !important; */
    border: 1px solid #717171 !important;
}

.quantity-button:last-child {
    /* border: 1px solid #dfe3e9 !important; */
    border-top: 1px solid #717171 !important;
}
</style>
<style>
pre {
    outline: 1px solid #ccc;
    padding: 5px;
    margin: 5px;
}
.success {
    color: #3e7c5b;
}
.failed {
    color: red;
}
</style>
